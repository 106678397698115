<template>
  <div class="edit">
    <!-- 修改资料 -->
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      size="small"
      label-position="right"
      :label-width="'100px'"
    >
      <el-form-item label="AccessKey：" prop="AccessKey">
        <el-input
          v-model="form.AccessKey"
          placeholder="请输入AccessKey"
        ></el-input>
      </el-form-item>
      <el-form-item label="Secret：" prop="Secret">
        <el-input v-model="form.Secret" placeholder="请输入Secret"></el-input>
      </el-form-item>
      <el-form-item label="bucket：" prop="bucket">
        <el-input v-model="form.bucket" placeholder="请输入bucket"></el-input>
      </el-form-item>
      <el-form-item label="endpoint：" prop="endpoint">
        <el-input
          v-model="form.endpoint"
          placeholder="请输入endpoint"
        ></el-input>
      </el-form-item>

      <el-form-item label="cname：" prop="cname">
        <el-input v-model="form.cname" placeholder="请输入cname"></el-input>
      </el-form-item>

      <el-form-item label="目录名称：" prop="dir">
        <el-input v-model="form.dir" placeholder="请输入目录名称"></el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-switch
          v-model="form.alioss"
          :active-value="1"
          :inactive-value="0"
          active-color="#0e71b3"
          @change="changeStatus"
        ></el-switch>
      </el-form-item>
      <el-form-item>
        <div>
          <el-button
            type="primary"
            size="small"
            class="btn_w"
            @click="handleEditSave('form')"
            >保存</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showEdit: false,
      form: {
        AccessKey: "",
        Secret: "",
        bucket: "",
        endpoint: "",
        cname: "",
        dir: "",
        alioss: 0,
      },
      rules: {
        AccessKey: [
          {
            required: true,
            message: "AccessKey不能为空",
            trigger: "blur",
          },
        ],
        Secret: [
          {
            required: true,
            message: "Secret不能为空",
            trigger: "blur",
          },
        ],
        bucket: [
          {
            required: true,
            message: "bucket不能为空",
            trigger: "blur",
          },
        ],
        endpoint: [
          {
            required: true,
            message: "endpoint不能为空",
            trigger: "blur",
          },
        ],
        cname: [
          {
            required: true,
            message: "cname不能为空",
            trigger: "blur",
          },
        ],
        dir: [
          {
            required: true,
            message: "目录不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getAlioss();
  },
  methods: {
    handleEditSave(form) {
      console.log(form);
        this.$refs[form].validate((valid) => {
          if (valid) {
            this.$axios
              .post("admin/config/saveAlioss", {
                AccessKey: this.form.AccessKey,
                Secret: this.form.Secret,
                bucket: this.form.bucket,
                endpoint: this.form.endpoint,
                cname: this.form.cname,
                dir: this.form.dir,
                alioss: this.form.alioss,
              })
              .then((res) => {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              });
          } else {
            return false;
          }
        });
    },
    getAlioss() {
      this.$axios
        .get("admin/config/getAlioss")
        .then((res) => {
          this.form.AccessKey = res.data.ak;
          this.form.Secret = res.data.sk;
          this.form.bucket = res.data.bucket;
          this.form.endpoint = res.data.endpoint;
          this.form.cname = res.data.cname;
          this.form.dir = res.data.dir;
          this.form.alioss = res.data.alioss;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 改变状态
    changeStatus(e) {
      console.log(e);
    //   if (e.alioss == 1) {
    //     this.form.alioss = 0;
    //   } else {
    //     this.form.alioss = 1;
    //   }
    },
  },
};
</script>

<style scoped>
.edit {
  margin: 15px;
  width: 30%;
}

.btn_w {
  width: 90px;
}
</style>
